<template>
    <div class="mx-6 text-xl font-bold">
        <div>Thank you for agreeing to support rappers in your state. Below is a 3-step explanation of how RapLand works. </div>
        <div class="font-black mt-5">Step 1</div>
        <div class="mt-5">Artists will post new music, videos, and content to their fans on social media and their RapLand profile.</div>
        <div class="font-black mt-5">Step 2</div>
        <div class="mt-5">Community Members will vote on the top rapper each week.</div>
        <div class="font-black mt-5">Step 3</div>
        <div class="mt-5">Every Monday at 12:00AM EST we will release the results via RapLand and email..</div>

    </div>
</template>

<script>
export default {
    name: 'RapLandBasics',

}
</script>